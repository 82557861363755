export default class Item {
    constructor() {
        this.itemName = null;
        this.itemId = null;
        this.itemAvailability = null;
        this.itemPricing = null;
        this.itemCategory = null;
        this.itemCategory2 = null;
        this.promotionId = null;
        this.promotionName = null;
        this.index = null;
        this.quantity = null;
        this.price = null;
        this.priceTarif = null;
        this.pricePromo = null;
        this.itemListName = null;
        this.refEquip = null;
    }
}
