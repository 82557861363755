import AddToCartNormalizersResolver from "./AddToCartNormalizersResolver";
import AbstractDataBuilder from "../../core/AbstractDataBuilder";
export default class AddToCartDataBuilder extends AbstractDataBuilder {
    static create() {
        if (undefined === this.builderInstance) {
            this.builderInstance = new this(new AddToCartNormalizersResolver());
        }
        return this.builderInstance;
    }
}
