import AbstractDataBuilder from "../../core/AbstractDataBuilder";
import FilterNormalizersResolver from "./FilterNormalizersResolver";
export default class FilterDataBuilder extends AbstractDataBuilder {
    static create() {
        if (undefined === this.builderInstance) {
            this.builderInstance = new this(new FilterNormalizersResolver());
        }
        return this.builderInstance;
    }
}
