import AbstractNormalizersResolver from "../../core/AbstractNormalizersResolver";
import ConsummableViewItemListNormalizer from "./ConsummableViewItemListNormalizer";
import PieceViewItemListNormalizer from "./PieceViewItemListNormalizer";
import TyresViewItemListNormalizer from "./TyresViewItemListNormalizer";
export default class ViewItemListNormalizersResolver extends AbstractNormalizersResolver {
    register() {
        return [
            new PieceViewItemListNormalizer(),
            new TyresViewItemListNormalizer(),
            new ConsummableViewItemListNormalizer(),
        ];
    }
}
