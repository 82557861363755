import AddToCartFromFolderNormalizer from "./AddToCartFromFolderNormalizer";
import AddToCartFromQuoteNormalizer from "./AddToCartFromQuoteNormalizer";
import AddToCartNormalizer from "./AddToCartNormalizer";
import AbstractNormalizersResolver from "../../core/AbstractNormalizersResolver";
import AddToCartFavorisNormalizer from "./AddToCartFavorisNormalizer";
import AddToCartSidexaNormalizer from "./AddToCartSidexaNormalizer";
export default class AddToCartNormalizersResolver extends AbstractNormalizersResolver {
    register() {
        return [
            new AddToCartFromFolderNormalizer(),
            new AddToCartFromQuoteNormalizer(),
            new AddToCartFavorisNormalizer(),
            new AddToCartSidexaNormalizer(),
            new AddToCartNormalizer(),
        ];
    }
}
