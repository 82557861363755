import AbstractDataBuilder from "../../core/AbstractDataBuilder";
import ViewItemNormalizersResolver from "./ViewItemNormalizersResolver";
export default class ViewItemDataBuilder extends AbstractDataBuilder {
    static create() {
        if (undefined === this.builderInstance) {
            this.builderInstance = new this(new ViewItemNormalizersResolver());
        }
        return this.builderInstance;
    }
}
