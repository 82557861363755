import { GA_CLASS } from "../../constants";
import { getModePrice } from '../../utilities';
import { ItemFactory } from "../dto/item/ItemFactory";
import AddToCartEvent from "./AddToCartEvent";
export default class AddToCartNormalizer {
    normalize(elements, context) {
        const addToCartEvent = new AddToCartEvent();
        const parentDiv = $(elements).closest(':has(.ga)');
        if (1 !== parentDiv.length) {
            return addToCartEvent;
        }
        const gaDiv = $(parentDiv).find(GA_CLASS);
        if (1 !== gaDiv.length) {
            return addToCartEvent;
        }
        const item = ItemFactory.createFromDataAttribute(gaDiv);
        item.itemPricing = getModePrice();
        item.index = 1;
        addToCartEvent.itemCollection.addItem(item);
        return addToCartEvent;
    }
    support(element, context) {
        return true;
    }
}
