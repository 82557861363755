import PurchaseEvent from "./PurchaseEvent";
export default class PurchasePunchoutNormalizer {
    normalize(elements, origin) {
        var _a;
        const purchaseEvent = new PurchaseEvent();
        const div = $('#download-cart');
        if (0 === div.length) {
            return null;
        }
        purchaseEvent.movexTransactionId = (_a = div.attr('data-movex-orno')) !== null && _a !== void 0 ? _a : null;
        Object.assign(purchaseEvent, this.getTableData(div));
        return purchaseEvent;
    }
    support(element, origin) {
        return 'punchout' === origin;
    }
    getTableData(div) {
        return {
            movexTransactionId: null,
            shipping: null,
            transactionId: div.attr('data-numCde'),
            value: div.attr('data-total-amount'),
        };
    }
}
