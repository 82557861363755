export const DIMENSION = "par dimension";
export const BLOCK = 'tbody tr.space_btn_tr';
export const BLOCK_ORIGINE = '.origin-datas';
export const URL_SEARCH_AFF = '/recherche-vehicule/affectation-origine';
export const PATH = window.location.pathname;
export const inputSearch = $('#search');
export const HISTORY_BLOCK = '.get-history-link';
export const AUTOCOMPLETE_TYRE = '#pneusAutocomplete';
export const CONTAINER_DISPO = '.containerDispo';
export const INPUT_QUANTITY = '.input-quantity';
export const NET_PRICE_TTC = '.net-price-rate-ttc';
export const EXPRESS_SEARCH = 'recherche express';
export const SEARCH_BY_TEXT = 'Recherche par texte';
export const $productContent = $('.product-content');
export const CATEGORIES = {
    '/pieces': 'pieces',
    '/consumables': 'consumables',
    '/peintures': 'peintures',
    '/reference': 'peintures',
    '/consumables/reference': 'consumables',
    '/pneus': 'pneus',
    '/favoris': 'peintures',
    '/panier': 'cart',
    '/commande': 'command',
    '/punchout/recherche-origine': 'punchout',
    '/quotes/edit/': 'quotes',
    '/chains-socks': 'chaine',
}
export const POPIN_FICHE_PRODUIT = '#popinFicheProduit';
export const POPIN_TYRE = '#popinFpPneus';
export const RELATIVE_PAINT = '.relative-paint';
export const DATA_AVAILABILITY_VALUE = 'data-available-value';

export const FOLDER_COST = 'dossier de chiffrage';
export const MODAL_BODY = '.modal-body';
export const DATA_REFEQ = '[data-refeq="';
export const QUOTE_DATA = '#quote-data-fp-';
export const CODE_FAMILLE = 'code-famille';
export const IMMEDIATE_ORDER = 'commande immédiate';
export const isPaint = $("#paintAutocomplete").length !== 0;


export const DATA_SALE_PRICE = 'sale-price';
export const DATA_PURCHASE_PRICE = 'purchase-price';
export const DATA_QUANTITY = 'quantity';
export const DATA_AVAILABILITY = 'availability';
export const ATTR_SALE_PRICE = 'data-sale-price';
export const ATTR_PURCHASE_PRICE = 'data-purchase-price';
export const ATTR_QUANTITY = 'data-quantity';
export const ATTR_AVAILABILITY = 'data-availability';

export const AMPLITUDE_CLASS = '.amplitude';

export const GA_CLASS = '.ga';
export const DATA_PRICE = 'price';
export const DATA_PRICE_TARIF = 'price-tarif';
export const DATA_PRICE_PROMO = 'price-promo';
export const DATA_ITEM_AVAILABILITY = 'item-availability';
export const ATTR_PRICE = 'data-price';
export const ATTR_PRICE_TARIF = 'data-price-tarif';
export const ATTR_PRICE_PROMO = 'data-price-promo';
export const ATTR_ITEM_AVAILABILITY = 'data-item-availability';
