import AbstractNormalizersResolver from "../../core/AbstractNormalizersResolver";
import BeginCheckoutCartDetailsNormalizer from "./BeginCheckoutCartDetailsNormalizer";
import BeginCheckoutCartDetailsPunchoutNormalizer from "./BeginCheckoutCartDetailsPunchoutNormalizer";
import BeginCheckoutCartLayerNormalizer from "./BeginCheckoutCartLayerNormalizer";
import BeginCheckoutCartLayerPunchoutNormalizer from "./BeginCheckoutCartLayerPunchoutNormalizer";
export default class BeginCheckoutNormalizersResolver extends AbstractNormalizersResolver {
    register() {
        return [
            new BeginCheckoutCartDetailsNormalizer(),
            new BeginCheckoutCartDetailsPunchoutNormalizer(),
            new BeginCheckoutCartLayerNormalizer(),
            new BeginCheckoutCartLayerPunchoutNormalizer(),
        ];
    }
}
