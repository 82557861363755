import AbstractNormalizersResolver from "../../core/AbstractNormalizersResolver";
import PurchaseNormalizer from "./PurchaseNormalizer";
import PurchasePunchoutNormalizer from "./PurchasePunchoutNormalizer";
export default class PurchaseNormalizersResolver extends AbstractNormalizersResolver {
    register() {
        return [
            new PurchasePunchoutNormalizer(),
            new PurchaseNormalizer(),
        ];
    }
}
