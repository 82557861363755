import { ItemFactory } from "../dto/item/ItemFactory";
import { getModePrice } from '../../utilities';
import SelectItemEvent from "./SelectItemEvent";
import { GA_CLASS } from '../../constants';
export default class SelectItemNormalizer {
    normalize(element, catalog) {
        const selectItemEvent = new SelectItemEvent();
        const parentDiv = $(element).closest(':has(.ga)');
        if (1 !== parentDiv.length) {
            return selectItemEvent;
        }
        const gaDiv = $(parentDiv).find(GA_CLASS);
        if (1 !== gaDiv.length) {
            return selectItemEvent;
        }
        const item = ItemFactory.createFromDataAttribute($(gaDiv));
        item.itemPricing = getModePrice();
        item.index = 1;
        selectItemEvent.itemCollection.addItem(item);
        return selectItemEvent;
    }
    support(element, catalog) {
        return true;
    }
}
