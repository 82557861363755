import { GA_CLASS } from "../../constants";
import PurchaseEvent from "./PurchaseEvent";
import { ItemFactory } from "../dto/item/ItemFactory";
import { getModePrice } from '../../utilities';
export default class PurchaseNormalizer {
    normalize(elements, origin) {
        var _a;
        const purchaseEvent = new PurchaseEvent();
        const table = $('table#table-order-' + origin);
        if (0 === table.length) {
            return null;
        }
        const gaDivs = $(table).find(GA_CLASS);
        $(gaDivs).each(function (index, gaDiv) {
            const item = ItemFactory.createFromDataAttribute($(gaDiv));
            item.index = index + 1;
            item.itemPricing = getModePrice();
            purchaseEvent.itemCollection.addItem(item);
        });
        purchaseEvent.movexTransactionId = (_a = table.attr('data-movex-orno')) !== null && _a !== void 0 ? _a : null;
        Object.assign(purchaseEvent, this.getTableData(table));
        return purchaseEvent;
    }
    support(element, origin) {
        return true;
    }
    getTableData(table) {
        var _a;
        return {
            movexTransactionId: (_a = table.attr('data-movex-orno')) !== null && _a !== void 0 ? _a : null,
            shipping: null,
            transactionId: table.attr('data-numCde'),
            value: table.attr('data-total-amount'),
        };
    }
}
