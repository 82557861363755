import AddToQuotationEvent from "./AddToQuotationEvent";
import { getModePrice } from '../../utilities';
import { GA_CLASS } from "../../constants";
import { ItemFactory } from "../dto/item/ItemFactory";
export default class AddToQuotationNormalizer {
    normalize(elements, origin) {
        const addToQuotationEvent = new AddToQuotationEvent();
        const parentDiv = $(elements).closest(':has(.ga)');
        if (1 !== parentDiv.length) {
            return addToQuotationEvent;
        }
        const gaDiv = $(parentDiv).find(GA_CLASS);
        if (1 !== gaDiv.length) {
            return addToQuotationEvent;
        }
        const item = ItemFactory.createFromDataAttribute(gaDiv);
        item.itemPricing = getModePrice();
        item.index = 1;
        addToQuotationEvent.itemCollection.addItem(item);
        return addToQuotationEvent;
    }
    support(element, origin) {
        return true;
    }
}
