const CATEGORIES = {
    '/pieces': 'pieces',
    '/consumables': 'consumables',
    '/peintures': 'peintures',
    '/reference': 'peintures',
    '/consumables/reference' : 'consumables',
    '/pneus': 'pneus',
    '/favoris': 'peintures',
    '/panier': 'cart',
    '/commande': 'command',
    '/punchout/recherche-origine': 'punchout',
    '/quotes/edit/': 'quotes',
    '/chains-socks': 'chaine',
}
const CONTAINER_DISPO = '.containerDispo';
const URL_SEARCH_AFF = '/recherche-vehicule/affectation-origine';
const PATH = window.location.pathname;

export function checkErrorSearch() {
    const pageErrors = [
        '/pieces-1' , '/pieces-2', '/pieces-2c', '/pieces-2b', '/pieces-3', '/pieces-4', '/pieces-6', '/pieces-7',
        '/pieces-10', '/pieces-12'
    ]

    for (const elem in pageErrors) {
        if(PATH.indexOf(pageErrors[elem]) >= 0) {
            return true;
        }
    }

    return false;
}

export function checkBandAlert() {
    return $('.icon-sprite-warning-vehicule-choice').length === 0 && $('.bandeau-alerte .alerte').length !== 0;
}

export function getSearchType(blockDataLayer) {
    let searchType ;
    if (PATH.indexOf(URL_SEARCH_AFF) >=0) {
        searchType = 'immat-express'
    } else {
        searchType = blockDataLayer.data('typerecherche');
    }

    return searchType;
}

export function getSearchTerm(blockDataLayer){
    const inputSearch = $('#search');
    let searchTerm ;
    if (inputSearch.length === 0) {
        searchTerm = blockDataLayer.data('searchterm');
    } else {
        searchTerm = inputSearch.hasClass('has-immatriculation') ?
            $('.frameImmat').text().concat(' ').concat(inputSearch.val())
            : inputSearch.val();
    }

    return searchTerm;
}

export function getDataUser(blockDataLayer)
{
    let distribType = blockDataLayer.data('distribtype');
    switch (distribType) {
        case 'F':
            distribType = 'Filiale';
            break;
        case 'I':
            distribType = 'Indépendant';
            break;
        default:
            distribType = 'Autre';
            break;
    }

    return {
        'codErp' : blockDataLayer.data('coderp'),
        'codeDistrib' : blockDataLayer.data('codedistrib'),
        'packageDistrib' : blockDataLayer.data('packagedistrib'),
        'distribType' : distribType,
        'codrepCom': blockDataLayer.data('codrepcom'),
        'codrep': blockDataLayer.data('codrep'),
        'usererp': blockDataLayer.data('usererp'),
        'emailrep': blockDataLayer.data('emailrep'),
    }
}

export function getCodeCommProduct(category) {
    let codeCom = null;
    if ($.inArray(category, ['consumables', 'peintures', 'pneus']) > -1) {
        const blockContainer = $(CONTAINER_DISPO);
        codeCom = blockContainer.data('refcom');
    }

    return codeCom;
}

export function getCategory(path) {
    let category = '';
    for (const key in CATEGORIES) {
        if(path.indexOf(key) >= 0) {
            category = CATEGORIES[key];
        }
    }

    return category;
}

export function formatAvailability(data) {
    const availability = data.trim().replace(/[\r\n\t\--]+/g,'').replace(/ +(?= )/g,'');

    return availability.split('.')[0].trim();
}
