import AbstractDataBuilder from "../../core/AbstractDataBuilder";
import SelectItemNormalizersResolver from "./SelectItemNormalizersResolver";
export default class SelectItemDataBuilder extends AbstractDataBuilder {
    static create() {
        if (undefined === this.builderInstance) {
            this.builderInstance = new this(new SelectItemNormalizersResolver());
        }
        return this.builderInstance;
    }
}
