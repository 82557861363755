import ViewItemListEvent from './ViewItemListEvent';
import { ItemFactory } from "../dto/item/ItemFactory";
import { getModePrice } from '../../utilities';
export default class ConsummableViewItemListNormalizer {
    normalize(elements, catalog) {
        const viewItemListEvent = new ViewItemListEvent();
        const gaDivs = $(elements).find('.ga');
        $(gaDivs).each(function (index, gaDiv) {
            const item = ItemFactory.createFromDataAttribute($(gaDiv));
            item.itemPricing = getModePrice();
            item.index = index + 1;
            viewItemListEvent.itemCollection.addItem(item);
        });
        viewItemListEvent.itemNumber = viewItemListEvent.itemCollection.length();
        viewItemListEvent.sortOutType = 'par popularité';
        return viewItemListEvent;
    }
    support(element, catalog) {
        return true;
    }
}
