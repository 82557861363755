export default class AbstractNormalizersResolver {
    getRegisteredNormalizers() {
        if (undefined === this.normalizers) {
            this.normalizers = this.register();
        }
        return this.normalizers;
    }
    resolve(element, context) {
        let resolvedNormalizer = null;
        for (const normalizer of this.getRegisteredNormalizers()) {
            if (normalizer.support(element, context)) {
                resolvedNormalizer = normalizer;
                break;
            }
        }
        return resolvedNormalizer;
    }
}
