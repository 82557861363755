import { GA_CLASS } from "../../constants";
import { ItemFactory } from "../dto/item/ItemFactory";
import BeginCheckoutEvent from "./BeginCheckoutEvent";
import { getModePrice } from '../../utilities';
export default class BeginCheckoutCartDetailsPunchoutNormalizer {
    normalize(elements, origin) {
        const beginCheckoutEvent = new BeginCheckoutEvent();
        beginCheckoutEvent.transmissionType = 'download';
        const gaDivs = $('article ' + GA_CLASS);
        $(gaDivs).each(function (index, gaDiv) {
            const item = ItemFactory.createFromDataAttribute($(gaDiv));
            item.index = index + 1;
            item.itemPricing = getModePrice();
            beginCheckoutEvent.itemCollection.addItem(item);
        });
        return beginCheckoutEvent;
    }
    support(element, origin) {
        return 'cart-details-punchout' === origin;
    }
}
