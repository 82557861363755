import SelectPromotionEvent from "./SelectPromotionEvent";
export default class SelectPromotionImageFinderNormalizer {
    normalize(element, context) {
        const selectPromotionEvent = new SelectPromotionEvent();
        const img = $(element).find('img:first');
        const id = $(img).data('id');
        const promotionName = $(img).attr('alt');
        selectPromotionEvent.promotionId = id !== null && id !== void 0 ? id : '';
        selectPromotionEvent.promotionName = promotionName !== null && promotionName !== void 0 ? promotionName : '';
        return selectPromotionEvent;
    }
    support(element, context) {
        return 'image-finder' === context;
    }
}
