import AbstractNormalizersResolver from "../../core/AbstractNormalizersResolver";
import SelectPromotionImageFinderNormalizer from "./SelectPromotionImageFinderNormalizer";
import SelectPromotionNormalizer from "./SelectPromotionNormalizer";
export default class SelectPromotionNormalizersResolver extends AbstractNormalizersResolver {
    register() {
        return [
            new SelectPromotionImageFinderNormalizer(),
            new SelectPromotionNormalizer(),
        ];
    }
}
