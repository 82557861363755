import { FeatureFlag } from "../FeatureFlag";
export class GoogleAnalyticsProcessor {
    static track(event, data) {
        if (FeatureFlag.isGaEnabled()) {
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                'ecommerce': null
            });
            window.dataLayer.push({
                event: event,
                'ecommerce': data
            });
        }
    }
}
