import SortOutEvent from "./SortOutEvent";
export default class SortOutNormalizer {
    normalize(element, catalog) {
        const sortOutEvent = new SortOutEvent();
        sortOutEvent.sortOutType = element;
        return sortOutEvent;
    }
    support(element, catalog) {
        return true;
    }
}
