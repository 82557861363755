const CAROUSEL_PRODUCT = '.carousel-product';
const CART_PRODUCT_IMAGE = '.panier-product-image';

export function handleConsumableImageError() {
    $('.consumable-image:not(.consumable-image-failed) .product-image').one('error', function () {
        $(this).attr('src', $(this).data('unavailable'));
        $(this).next().hide();
        $(this).parent().addClass('consumable-image-failed');
    });
}

export function handleAffectedImageError() {
    $('.img-vignette.affected-img').one('error', function () {
        $(this).attr('src', $(this).data('unavailable'));
        $(this).parent().next().hide();
        $(this).parent().addClass('affected-image-failed');
    });
}

export function handlePneuImageError(container) {
    let elements = $('.product-image.pneu-image');
    if (container) {
        elements = container.find('.product-image.pneu-image');
    }
    elements.one('error', function () {
        $(this).attr('src', $(this).data('unavailable'));
        $(this).addClass('pneu-image-failed');
    });
}

export function handleFicheProduitImageError() {
    $('.fiche-produit-image').one('error', function () {
        $(this).attr('src', $(this).data('unavailable'));
        $(this).addClass('fiche-produit-image-failed');
        $(this).parent().addClass('fiche-produit-image-failed-container');
        $(this).closest('.owl-carousel').trigger('refresh.owl.carousel');
        $(this).closest(CAROUSEL_PRODUCT).children('.resize').hide();
    })
    $('.fiche-produit-optimized').one('error', function () {
        $(this).attr('src', $(this).data('unavailable'));
        $(this).addClass('fiche-produit-optimized-failed');
        $(this).parent().addClass('fiche-produit-optimized-failed-container');
        $(this).closest('.owl-carousel').trigger('refresh.owl.carousel');
    })
}

export function handlePanierImageError() {
    $(CART_PRODUCT_IMAGE).one('error', function () {
        $(this).attr('src', $(this).data('unavailable'));
        const zoomButton = $(this).parent().next();
        if (zoomButton.hasClass('zoom-button')) {
            zoomButton.hide();
        }
    });
}
