import AddToQuotationEvent from "./AddToQuotationEvent";
import { getModePrice } from '../../utilities';
import { GA_CLASS } from "../../constants";
import { ItemFactory } from "../dto/item/ItemFactory";
export default class AddToQuotationMultipleNormalizer {
    normalize(elements, origin) {
        const addToQuotationEvent = new AddToQuotationEvent();
        $(elements).each((index, element) => {
            const parentDiv = $(element).closest(':has(.ga)');
            if (1 !== parentDiv.length) {
                return false;
            }
            const gaDiv = $(parentDiv).find(GA_CLASS);
            if (1 !== gaDiv.length) {
                return false;
            }
            const item = ItemFactory.createFromDataAttribute(gaDiv);
            item.itemPricing = getModePrice();
            item.index = index + 1;
            addToQuotationEvent.itemCollection.addItem(item);
        });
        return addToQuotationEvent;
    }
    support(element, origin) {
        return origin == 'multiple-favorites';
    }
}
