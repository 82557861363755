import { ItemCollection } from "../dto/item/ItemCollection";
export default class BeginCheckoutEvent {
    constructor() {
        this.currency = 'EUR';
        this.transmissionType = '';
        this.itemCollection = new ItemCollection();
    }
    export() {
        return {
            currency: this.currency,
            items: this.itemCollection.export(),
            transmission_type: this.transmissionType
        };
    }
}
