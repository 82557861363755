import AbstractNormalizersResolver from "../../core/AbstractNormalizersResolver";
import CheckoutProgressCartDetailsNormalizer from "./CheckoutProgressCartDetailsNormalizer";
import CheckoutProgressCartLayerNormalizer from "./CheckoutProgressCartLayerNormalizer";
export default class CheckoutProgressNormalizersResolver extends AbstractNormalizersResolver {
    register() {
        return [
            new CheckoutProgressCartDetailsNormalizer(),
            new CheckoutProgressCartLayerNormalizer(),
        ];
    }
}
