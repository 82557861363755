import {
    URL_SEARCH_AFF,
    PATH,
    SEARCH_BY_TEXT,
    $productContent,
    EXPRESS_SEARCH,
    HISTORY_BLOCK,
    MODAL_BODY
} from "./constants";
import { VehicleFactory } from "./amplitude/dto/vehicle/VehicleFactory";
import $ from "jquery";

export function getGADataBlock() {
    return $('#dataGa');
}
export function checkCons(catalog) {
    return catalog === 'consumables' ? 'consommables' : catalog;
}

export function getSearchType(blockDataLayer) {
    let searchType;
    if (PATH.indexOf(URL_SEARCH_AFF) >= 0) {
        searchType = EXPRESS_SEARCH
    } else {
        searchType = blockDataLayer.data('typerecherche');
        searchType = manageTypeOfSearch(searchType);
    }

    return searchType;
}

function manageTypeOfSearch(searchType) {
    switch (searchType) {
        case 'express':
        case 'immat-express':
            searchType = EXPRESS_SEARCH;
            break;
        case 'mine':
            searchType = 'mine-cnit';
            break;
        case 'reforigine':
            searchType = 'ref constructeur';
            break;
        case 'refequip':
            searchType = 'ref equipementier';
            break;
        case 'multirefequip':
            searchType = 'multi ref equipementier';
            break;
        case 'reference':
            searchType = SEARCH_BY_TEXT;
            break;
        case 'family':
            const isBreadCrumb = $('#breadCrumbActived').val() === 'true';
            searchType = isBreadCrumb ? 'Recherche par Arborescence' : SEARCH_BY_TEXT;
            break;
        default:
            break;
    }

    return searchType;
}

export function getVehiclesData(blockDataLayer, vehicleDataBlock = null, origin = '') {
    const dataVehicles = [];
    if (null !== vehicleDataBlock) {
        dataVehicles.push(VehicleFactory.createFromDataAttribute($(vehicleDataBlock)));
    } else {
        const vehicles = ['catalog', 'header', 'dossier de chiffrage', 'search_vehicle_history'].includes(origin) ? blockDataLayer : $('.catalogue_cel '.concat(HISTORY_BLOCK));
        const mid = blockDataLayer.data('mid');
        vehicles.each(function (index, value) {
            const vehicle = JSON.parse($(value).attr('data-vehicle'));
            dataVehicles.push(VehicleFactory.createFromJson(vehicle, mid));
        })
    }

    return dataVehicles;
}

export function getFamily() {
    const autodocFamilyLabel = document.getElementById('autodoc-family-label');

    if (undefined !== autodocFamilyLabel && null !== autodocFamilyLabel) {
        return autodocFamilyLabel.textContent;
    }

    return $productContent.data('libfam');
}

export function getSubFamily() {
    const sousfam = document.getElementById('sousfam');

    if (undefined !== sousfam && null !== sousfam) {
        return sousfam.textContent;
    }

    return $productContent.data('libsfam');
}

export function getLibRep() {
    const libRep = document.getElementById('autodoc-librep');

    if (undefined !== libRep && null !== libRep) {
        return libRep.textContent;
    }

    return $productContent.data('librepere');
}

export function getModePrice() {
    const modePrice = $('#toggle_currency_mode:hidden:first').prop('checked');

    return modePrice ? 'prix_complet' : 'prix_public';
}


export function getDataQuantityChanged(element) {
    let origin;

    if ($(element).closest(MODAL_BODY).length > 0) {
        origin = 'item_view';
    } else if ($('section.section_cart').length > 0) {
        origin = 'cart_details';
    } else {
        origin = 'item_view_results';
    }

    return {
        'origin': origin
    }
}

export function exportVehicles(vehiclesDtoCollection) {
    const vehiclesExport = [];
    for (const vehicle of vehiclesDtoCollection) {
        vehiclesExport.push(vehicle.export());
    }

    return vehiclesExport;
}

export function getSelectedFamily(search) {
    const items = $('.search-pieces-autocomplete li.ui-menu-item');

    if (0 === items.length) {
        return null;
    }

    for (const item of items) {
        const dataItem = $(item).data('item');
        if (search.trim().toLowerCase() === dataItem.value.trim().toLowerCase()) {
            return dataItem;
        }
    }

    return null;
}

export function lowercaseObjectValues(obj) {
    if (typeof obj !== 'object' || obj === null) {
        return obj; // Base case: return non-object values as-is
    }

    if (Array.isArray(obj)) {
        // If the property is an array, recursively lowercase each element
        return obj.map(lowercaseObjectValues);
    }

    const newObj = {};
    for (let key in obj) {
        if (obj.hasOwnProperty(key)) {
            newObj[key] = lowercaseObjectValues(obj[key]);

            if (typeof newObj[key] === 'string') {
                newObj[key] = newObj[key].toLowerCase();
            }
        }
    }

    return newObj;
}
