import AbstractNormalizersResolver from "../../core/AbstractNormalizersResolver";
import SortOutNormalizer from "./SortOutNormalizer";
import SortOutSortTypeNormalizer from "./SortOutSortTypeNormalizer";
export default class SortOutNormalizersResolver extends AbstractNormalizersResolver {
    register() {
        return [
            new SortOutSortTypeNormalizer(),
            new SortOutNormalizer(),
        ];
    }
}
