import SortOutEvent from "./SortOutEvent";
export default class SortOutSortTypeNormalizer {
    normalize(element, catalog) {
        const sortOutEvent = new SortOutEvent();
        const upSort = element.prev('.sortName');
        const downSort = element.prev().prev('.sortName');
        sortOutEvent.sortOutType = upSort.length === 0 ? downSort.text() : upSort.text();
        return sortOutEvent;
    }
    support(element, catalog) {
        return 'sort-type' === catalog;
    }
}
