import AddToQuotationNormalizer from "./AddToQuotationNormalizer";
import AbstractNormalizersResolver from "../../core/AbstractNormalizersResolver";
import AddToQuotationMultipleNormalizer from "./AddToQuotationMultipleNormalizer";
export default class AddToQuotationNormalizersResolver extends AbstractNormalizersResolver {
    register() {
        return [
            new AddToQuotationMultipleNormalizer(),
            new AddToQuotationNormalizer(),
        ];
    }
}
