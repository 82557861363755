import { ItemFactory } from "../dto/item/ItemFactory";
import { getModePrice } from '../../utilities';
import ViewItemEvent from "./ViewItemEvent";
import { GA_CLASS } from '../../constants';
export default class ViewItemNormalizer {
    normalize(element, catalog) {
        const viewItemEvent = new ViewItemEvent();
        const parentDiv = $(element).closest(':has(.ga)');
        if (1 !== parentDiv.length) {
            return viewItemEvent;
        }
        const gaDiv = $(parentDiv).find(GA_CLASS);
        if (1 !== gaDiv.length) {
            return viewItemEvent;
        }
        const item = ItemFactory.createFromDataAttribute($(gaDiv));
        item.itemPricing = getModePrice();
        item.index = 1;
        viewItemEvent.itemCollection.addItem(item);
        return viewItemEvent;
    }
    support(element, catalog) {
        return true;
    }
}
