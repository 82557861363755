import ClickMatchFamilyEvent from "./ClickMatchFamilyEvent";
export default class ClickMatchFamilyNormalizer {
    normalize(element, catalog) {
        const clickMatchFamilyEvent = new ClickMatchFamilyEvent();
        const href = $(element).attr('href').split('/');
        clickMatchFamilyEvent.codeFamMatch = href[href.length - 1];
        return clickMatchFamilyEvent;
    }
    support(element, catalog) {
        return true;
    }
}
