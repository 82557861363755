import { GA_CLASS } from "../../constants";
import { ItemFactory } from "../dto/item/ItemFactory";
import CheckoutProgressEvent from "./CheckoutProgressEvent";
import { getModePrice } from '../../utilities';
export default class CheckoutProgressCartDetailsNormalizer {
    normalize(elements, origin) {
        const beginCheckoutEvent = new CheckoutProgressEvent();
        beginCheckoutEvent.checkoutType = 'validation';
        const gaDivs = $('article ' + GA_CLASS);
        $(gaDivs).each(function (index, gaDiv) {
            const item = ItemFactory.createFromDataAttribute($(gaDiv));
            item.index = index + 1;
            item.itemPricing = getModePrice();
            beginCheckoutEvent.itemCollection.addItem(item);
        });
        return beginCheckoutEvent;
    }
    support(element, origin) {
        return 'cart-details' === origin;
    }
}
