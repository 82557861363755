import RemoveFromCartDetailsNormalizer from "./RemoveFromCartDetailsNormalizer";
import RemoveFromCartNormalizer from "./RemoveFromCartNormalizer";
import RemoveFromFolderCostNormalizer from "./RemoveFromFolderCostNormalizer";
import AbstractNormalizersResolver from "../../core/AbstractNormalizersResolver";
export default class RemoveFromCartNormalizersResolver extends AbstractNormalizersResolver {
    register() {
        return [
            new RemoveFromCartDetailsNormalizer(),
            new RemoveFromFolderCostNormalizer(),
            new RemoveFromCartNormalizer(),
        ];
    }
}
