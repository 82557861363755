import { GA_CLASS } from "../../constants";
import { ItemFactory } from "../dto/item/ItemFactory";
import RemoveFromCartEvent from "./RemoveFromCartEvent";
import { getModePrice } from '../../utilities';
export default class RemoveFromCartDetailsNormalizer {
    normalize(elements, origin) {
        const removeFromCartEvent = new RemoveFromCartEvent();
        const parentDiv = $(elements).closest('article');
        if (1 === parentDiv.length) {
            const gaDiv = $(parentDiv).find(GA_CLASS);
            const item = ItemFactory.createFromDataAttribute($(gaDiv));
            item.index = 1;
            item.itemPricing = getModePrice();
            removeFromCartEvent.itemCollection.addItem(item);
            return removeFromCartEvent;
        }
        const gaDivs = $('article ' + GA_CLASS);
        $(gaDivs).each(function (index, gaDiv) {
            const item = ItemFactory.createFromDataAttribute($(gaDiv));
            item.index = index + 1;
            item.itemPricing = getModePrice();
            removeFromCartEvent.itemCollection.addItem(item);
        });
        return removeFromCartEvent;
    }
    support(element, origin) {
        return 'cart_details' === origin;
    }
}
