import { FOLDER_COST, GA_CLASS } from "../../constants";
import { getModePrice } from '../../utilities';
import { ItemFactory } from "../dto/item/ItemFactory";
import AddToCartEvent from "./AddToCartEvent";
export default class AddToCartFromFolderNormalizer {
    normalize(elements, context) {
        const addToCartEvent = new AddToCartEvent();
        $('td .folder-chosen').each(function (index, value) {
            const gaDiv = $(value).closest('td').find(GA_CLASS);
            if (1 === gaDiv.length) {
                const item = ItemFactory.createFromDataAttribute($(gaDiv));
                item.itemPricing = getModePrice();
                item.index = 1;
                addToCartEvent.itemCollection.addItem(item);
            }
        });
        const offCatalogClass = ".ref-oe-container.bloc-reference.folder-offcatalog";
        $(offCatalogClass + ' .folder-chosen').each(function (index, value) {
            const gaDiv = $(value).closest(offCatalogClass).find(GA_CLASS);
            if (1 === gaDiv.length) {
                const item = ItemFactory.createFromDataAttribute($(gaDiv));
                item.itemPricing = getModePrice();
                item.index = 1;
                addToCartEvent.itemCollection.addItem(item);
            }
        });
        return addToCartEvent;
    }
    support(element, context) {
        return FOLDER_COST === context;
    }
}
