import { ItemFactory } from "../dto/item/ItemFactory";
import { getModePrice } from '../../utilities';
import AddToCartEvent from "./AddToCartEvent";
export default class AddToCartFromQuoteNormalizer {
    normalize(elements, context) {
        const addToCartEvent = new AddToCartEvent();
        let gaDivs = $('table.quotes-result--results-list .ga');
        $(gaDivs).each(function (index, gaDiv) {
            const item = ItemFactory.createFromDataAttribute($(gaDiv));
            item.itemPricing = getModePrice();
            item.index = 1;
            addToCartEvent.itemCollection.addItem(item);
        });
        return addToCartEvent;
    }
    support(element, context) {
        return "devis entretien" === context;
    }
}
