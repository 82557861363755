import { GA_CLASS } from "../../constants";
import { getModePrice } from '../../utilities';
import { ItemFactory } from "../dto/item/ItemFactory";
import AddToCartEvent from "./AddToCartEvent";
export default class AddToCartFavorisNormalizer {
    normalize(elements, context) {
        const addToCartEvent = new AddToCartEvent();
        $(elements).each(function (index, value) {
            const gaDiv = $(value).closest('.consumable-product').find(GA_CLASS);
            if (1 === gaDiv.length) {
                const item = ItemFactory.createFromDataAttribute($(gaDiv));
                item.itemPricing = getModePrice();
                item.index = index + 1;
                addToCartEvent.itemCollection.addItem(item);
            }
        });
        return addToCartEvent;
    }
    support(element, context) {
        return 'favoris' === context;
    }
}
