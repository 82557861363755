export class ItemCollection {
    constructor() {
        this.items = [];
    }
    addItem(item) {
        this.items.push(item);
        return this;
    }
    export() {
        const exportedItems = [];
        for (const item of this.items) {
            exportedItems.push(this.exportItem(item));
        }
        return exportedItems;
    }
    length() {
        return this.items.length;
    }
    exportItem(item) {
        return {
            'item_name': item.itemName,
            'item_id': item.itemId,
            'item_availability': item.itemAvailability,
            'item_pricing': item.itemPricing,
            'item_category': item.itemCategory,
            'item_category2': item.itemCategory2,
            'promotion_id': item.promotionId,
            'promotion_name': item.promotionName,
            'index': item.index,
            'quantity': item.quantity,
            'price': item.price,
            'price_tarif': item.priceTarif,
            'price_promo': item.pricePromo,
            'item_list_name': item.itemListName,
            'ref_equip': item.refEquip,
        };
    }
}
