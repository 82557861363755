import SelectPromotionEvent from "./SelectPromotionEvent";
export default class SelectPromotionNormalizer {
    normalize(element, context) {
        const selectPromotionEvent = new SelectPromotionEvent();
        const id = $(element).find('a.zoom-button-promo').data('rel');
        const promotionName = $(element).parent().find('h4.title').text();
        selectPromotionEvent.promotionId = id !== null && id !== void 0 ? id : '';
        selectPromotionEvent.promotionName = promotionName !== null && promotionName !== void 0 ? promotionName : '';
        return selectPromotionEvent;
    }
    support(element, context) {
        return 'tab-panel' === context;
    }
}
