import {formatAvailability} from "./manage-ga";
import $ from "jquery";
import {handleFicheProduitImageError} from "./imageErrors";
import GaEventHandler from "./analytics/ga/GaEventHandler";

const PRODUCT_SHEET = "#popinFicheProduit";
const DIAPORAMA_VIGN = ".DiaporamaVignette";

/**
 *
 * @param {type} url
 * @param {type} product
 * @returns {Boolean}
 */
export function getFicheProduitAjax(url) {
    const source = $('#source').val();
    if (source !== undefined && '' !== source) {
        url = url.concat('?source=').concat(source);
    }
    $(PRODUCT_SHEET).load(url, function () {
        $(PRODUCT_SHEET).parent().show();
        $(PRODUCT_SHEET).modal({});
        let overlayDIV = $(PRODUCT_SHEET).closest('body').find('.overlay');
        $(overlayDIV[1]).css('display', 'none');
        $('.ps-price-block.keep-price').addClass('complete-price');

        GaEventHandler.viewItem($(this),'getFicheProduitAjax');

        const blockAvailability = $(PRODUCT_SHEET).find('#blocDispo .dispo-details');
        let availability = blockAvailability.children(':not(.tooltips)').text();
        if (blockAvailability.find('input.datepicker-pricing').length > 0) {
            availability = blockAvailability.find('input.datepicker-pricing').attr('value');
        } else if (blockAvailability.find('.available-on-demand').length > 0) {
            availability = blockAvailability.find('.available-on-demand').text();
        } else if (blockAvailability.find('.available-on-order').length > 0) {
            availability = blockAvailability.find('.available-on-order').text();
        }
        availability = formatAvailability(availability);
        $(PRODUCT_SHEET).find('#blocDispo').attr('data-available-value', availability);
        handleFicheProduitImageError();
    });
    return false;
}

(function ($) {
    $(function () {
        $(DIAPORAMA_VIGN).hide();
        // click outside close zoom
        $('body').bind('click', function (event) {
            if (!$(event.target).closest(DIAPORAMA_VIGN).length && !$(event.target).closest('.produit-visuel-container').length) {
                if ($(DIAPORAMA_VIGN).is(":visible")) {
                    $(DIAPORAMA_VIGN).fadeOut();
                }
            }
        });
        $(document).on('click', '.zoom-button', function (e) {
            e.preventDefault();
            $(DIAPORAMA_VIGN).hide();
            var top = (e.currentTarget.offsetTop) - 160;
            var left = (e.currentTarget.offsetLeft) + 70;
            var target = $(this).attr('data-rel');
            // zoom fiche produit
            var zoomdatarel = $(this).attr('data-rel');
            if (zoomdatarel === 'carouselProduct') {
                top = 0;
                left = 271;
            }
            $(this).parent().parent().find('.lazy.owl-lazy').each(function (i, el) {
                $(el).css('opacity', '1');
            })
            if (typeof (target) != 'undefined') {

                var img = $('#' + target).find('img.lazy');
                var imageW = $(img)[0].width + 16;
                $('#' + target).hide();
                $('#' + target).css('width', imageW + 'px');
                $('#' + target).css('top', top + 'px');
                $('#' + target).css('left', left + 'px');
                $('#' + target).css('border', '2px solid #8c8c8c');
                $('#' + target).show();
                $('#' + target).fadeIn();
            }
        });
        $(document).on('click', '.DiaporamaVignette .close-diap', function () {
            $(DIAPORAMA_VIGN).fadeOut();
        });
    });
})($);
(function ($) {
    $(function () {
        $('.toggle-ht-ttc .toggle').toggles({
            text: {
                'on': '',
                'off': ''
            }
        });
        $('.toggle-ht-ttc .toggle').on('toggle', function (e, active) {
            // active = false => HT selected
            // active = true => TTC selected
            var $priceBlock = $('.productDetail');
            $priceBlock.find('[data-price-ht]').each(function () {
                var $this = $(this);
                if (typeof ($this.attr('data-price-ht')) == 'undefined' || typeof ($this.attr('data-price-ttc')) == 'undefined') {
                    return;
                }
                if (active) {
                    $this.html($this.attr('data-price-ttc') + ' &euro; TTC');
                } else {
                    $this.html($this.attr('data-price-ht') + ' &euro; HT');
                }
            });
        });
    });
})($);
