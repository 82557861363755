export default class AbstractDataBuilder {
    static create() {
        throw new Error("Builder create method not implemented !");
    }
    constructor(normalizersResolver) {
        this.normalizersResolver = normalizersResolver;
    }
    build(element, context) {
        const normalizer = this.normalizersResolver.resolve(element, context);
        if (null !== normalizer) {
            return normalizer.normalize(element, context);
        }
        return null;
    }
}
