import { FeatureFlag } from "../FeatureFlag";
import { GoogleAnalyticsProcessor } from "./GoogleAnalyticsProcessor";
import SelectItemDataBuilder from "./selectItem/SelectItemDataBuilder";
import ViewItemDataBuilder from "./viewItem/ViewItemDataBuilder";
import ViewItemListDataBuilder from "./viewItemList/ViewItemListDataBuilder";
import AddToCartDataBuilder from "./addToCart/AddToCartDataBuilder";
import AddToQuotationDataBuilder from "./addToQuotation/AddToQuotationDataBuilder";
import AddToWishlistDataBuilder from "./addToWishlist/AddToWishlistDataBuilder";
import BeginCheckoutDataBuilder from "./beginCheckout/BeginCheckoutDataBuilder";
import CheckAvailabilityDataBuilder from "./checkAvailability/CheckAvailabilityDataBuilder";
import CheckoutProgressDataBuilder from "./checkoutProgress/CheckoutProgressDataBuilder";
import PurchaseDataBuilder from "./purchase/PurchaseDataBuilder";
import RemoveFromCartDataBuilder from "./removeFromCart/RemoveFromCartDataBuilder";
import SelectPromotionDataBuilder from "./selectPromition/SelectPromotionDataBuilder";
import SwitchPricingDataBuilder from "./switchPricing/SwitchPricingDataBuilder";
import FilterDataBuilder from "./filter/FilterDataBuilder";
import SortOutDataBuilder from "./sortOut/SortOutDataBuilder";
import ClickMatchFamilyDataBuilder from "./clickMatchFamily/ClickMatchFamilyDataBuilder";
import ClickSearchCategoryDataBuilder from "./clickSearchCategory/ClickSearchCategoryDataBuilder";
export default class GaEventHandler {
    static viewItemList(elements, category) {
        if (false === FeatureFlag.isGaEnabled()) {
            return;
        }
        const builder = ViewItemListDataBuilder.create();
        const dto = builder.build(elements, category);
        if (null === dto) {
            return;
        }
        GoogleAnalyticsProcessor.track('view_item_list', dto.export());
    }
    static selectItem(elements, category) {
        if (false === FeatureFlag.isGaEnabled()) {
            return;
        }
        const builder = SelectItemDataBuilder.create();
        const dto = builder.build(elements, category);
        if (null === dto) {
            return;
        }
        GoogleAnalyticsProcessor.track('select_item', dto.export());
    }
    static viewItem(elements, category) {
        if (false === FeatureFlag.isGaEnabled()) {
            return;
        }
        const builder = ViewItemDataBuilder.create();
        const dto = builder.build(elements, category);
        if (null === dto) {
            return;
        }
        GoogleAnalyticsProcessor.track('view_item', dto.export());
    }
    static addToCart(elements, category) {
        if (false === FeatureFlag.isGaEnabled()) {
            return;
        }
        const builder = AddToCartDataBuilder.create();
        const dto = builder.build(elements, category);
        if (null === dto) {
            return;
        }
        GoogleAnalyticsProcessor.track('add_to_cart', dto.export());
    }
    // cart
    static removeFromCart(element, origin) {
        if (false === FeatureFlag.isEnabled()) {
            return;
        }
        const builder = RemoveFromCartDataBuilder.create();
        const dto = builder.build(element, origin);
        if (null === dto) {
            return;
        }
        GoogleAnalyticsProcessor.track('remove_from_cart', dto.export());
    }
    static beginCheckout(element, origin) {
        if (false === FeatureFlag.isEnabled()) {
            return;
        }
        const builder = BeginCheckoutDataBuilder.create();
        const dto = builder.build(element, origin);
        if (null === dto) {
            return;
        }
        GoogleAnalyticsProcessor.track('begin_checkout', dto.export());
    }
    static checkoutProgress(element, origin) {
        if (false === FeatureFlag.isEnabled()) {
            return;
        }
        const builder = CheckoutProgressDataBuilder.create();
        const dto = builder.build(element, origin);
        if (null === dto) {
            return;
        }
        GoogleAnalyticsProcessor.track('checkout_progress', dto.export());
    }
    static checkAvailability(element, origin) {
        if (false === FeatureFlag.isEnabled()) {
            return;
        }
        const builder = CheckAvailabilityDataBuilder.create();
        const dto = builder.build(element, origin);
        if (null === dto) {
            return;
        }
        GoogleAnalyticsProcessor.track('check_availability', dto.export());
    }
    static addToWishList(element, origin) {
        if (false === FeatureFlag.isEnabled()) {
            return;
        }
        const builder = AddToWishlistDataBuilder.create();
        const dto = builder.build(element, origin);
        if (null === dto) {
            return;
        }
        GoogleAnalyticsProcessor.track('add_to_wishlist', dto.export());
    }
    static removeFromWishList(element, origin) {
        if (false === FeatureFlag.isEnabled()) {
            return;
        }
        const builder = RemoveFromCartDataBuilder.create();
        const dto = builder.build(element, origin);
        if (null === dto) {
            return;
        }
        GoogleAnalyticsProcessor.track('remove_from_wishlist', dto.export());
    }
    static addToQuotation(element, origin) {
        if (false === FeatureFlag.isEnabled()) {
            return;
        }
        const builder = AddToQuotationDataBuilder.create();
        const dto = builder.build(element, origin);
        if (null === dto) {
            return;
        }
        GoogleAnalyticsProcessor.track('add_to_quotation', dto.export());
    }
    static purchase(element, origin) {
        if (false === FeatureFlag.isEnabled()) {
            return;
        }
        const builder = PurchaseDataBuilder.create();
        const dto = builder.build(element, origin);
        if (null === dto) {
            return;
        }
        GoogleAnalyticsProcessor.track('purchase', dto.export());
    }
    static selectPromotion(element, origin) {
        if (false === FeatureFlag.isEnabled()) {
            return;
        }
        const builder = SelectPromotionDataBuilder.create();
        const dto = builder.build(element, origin);
        if (null === dto) {
            return;
        }
        GoogleAnalyticsProcessor.track('select_promotion', dto.export());
    }
    static switchPricing(element, origin) {
        if (false === FeatureFlag.isEnabled()) {
            return;
        }
        const builder = SwitchPricingDataBuilder.create();
        const dto = builder.build(element, origin);
        if (null === dto) {
            return;
        }
        GoogleAnalyticsProcessor.track('switch_pricing', dto.export());
    }
    static filter(element, origin) {
        if (false === FeatureFlag.isEnabled()) {
            return;
        }
        const builder = FilterDataBuilder.create();
        const dto = builder.build(element, origin);
        if (null === dto) {
            return;
        }
        GoogleAnalyticsProcessor.track('filter', dto.export());
    }
    static sortOut(element, origin) {
        if (false === FeatureFlag.isEnabled()) {
            return;
        }
        const builder = SortOutDataBuilder.create();
        const dto = builder.build(element, origin);
        if (null === dto) {
            return;
        }
        GoogleAnalyticsProcessor.track('sort_out', dto.export());
    }
    static ClickMatchFamily(element, origin) {
        if (false === FeatureFlag.isEnabled()) {
            return;
        }
        const builder = ClickMatchFamilyDataBuilder.create();
        const dto = builder.build(element, origin);
        if (null === dto) {
            return;
        }
        GoogleAnalyticsProcessor.track('click_match_family', dto.export());
    }
    static clickEquivalentProduct(element, origin) {
        if (false === FeatureFlag.isEnabled()) {
            return;
        }
        GoogleAnalyticsProcessor.track('click_equivalent_product', []);
    }
    static clickSearchCategory(element, origin) {
        if (false === FeatureFlag.isEnabled()) {
            return;
        }
        const builder = ClickSearchCategoryDataBuilder.create();
        const dto = builder.build(element, origin);
        if (null === dto) {
            return;
        }
        GoogleAnalyticsProcessor.track('click_search_category', dto.export());
    }
}
