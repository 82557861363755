import { ItemCollection } from "../dto/item/ItemCollection";
export default class ViewItemListEvent {
    constructor() {
        this.currency = 'EUR';
        this.filterType = '';
        this.itemNumber = 0;
        this.sortOutType = '';
        this.itemCollection = new ItemCollection();
    }
    export() {
        return {
            currency: this.currency,
            filter_type: this.filterType,
            item_number: this.itemNumber,
            items: this.itemCollection.export(),
            sort_out_type: this.sortOutType,
        };
    }
}
