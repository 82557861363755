import { ItemCollection } from "../dto/item/ItemCollection";
export default class AddToWishlistEvent {
    constructor() {
        this.currency = 'EUR';
        this.itemCollection = new ItemCollection();
    }
    export() {
        return {
            currency: this.currency,
            items: this.itemCollection.export(),
        };
    }
}
