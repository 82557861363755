import { Vehicle } from "./Vehicle";
export class VehicleFactory {
    static createFromJson(vehicle, mid) {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m;
        const vehicleDto = new Vehicle();
        if (null === vehicle) {
            return vehicleDto;
        }
        vehicleDto.vehiculeImmat = (_a = vehicle === null || vehicle === void 0 ? void 0 : vehicle.IMMAT) !== null && _a !== void 0 ? _a : '';
        vehicleDto.vehiculeBrand = (_b = vehicle.LIBMAR) !== null && _b !== void 0 ? _b : '';
        vehicleDto.vehiculeModel = (_c = vehicle.MODELE) !== null && _c !== void 0 ? _c : '';
        vehicleDto.vehicleVersion = (_d = vehicle.LIBGRO) !== null && _d !== void 0 ? _d : '';
        vehicleDto.vehicleDateMec = (_e = vehicle.S_DATE1ERCIR_FULL) !== null && _e !== void 0 ? _e : '';
        vehicleDto.vehicleNumord = (_f = vehicle.NUMORD) !== null && _f !== void 0 ? _f : '';
        vehicleDto.vehicleMid = mid !== null && mid !== void 0 ? mid : '';
        vehicleDto.vehicleKtype = (_g = vehicle.KTYPNR) !== null && _g !== void 0 ? _g : '';
        vehicleDto.vehicleVin = (_h = vehicle.CODIFVIN) !== null && _h !== void 0 ? _h : '';
        vehicleDto.vehicleCodmar = (_j = vehicle.CODMAR) !== null && _j !== void 0 ? _j : '';
        vehicleDto.vehicleCodemodel = (_k = vehicle.CODGRO) !== null && _k !== void 0 ? _k : '';
        vehicleDto.vehicleYear = (_m = VehicleFactory.getYearFromDateMec((_l = vehicle.S_DATE1ERCIR_FULL) !== null && _l !== void 0 ? _l : '')) !== null && _m !== void 0 ? _m : '';
        return vehicleDto;
    }
    static createFromDataAttribute(element) {
        var _a;
        const vehicleDto = new Vehicle();
        vehicleDto.vehiculeImmat = element.attr('data-immat');
        vehicleDto.vehiculeBrand = element.attr('data-libmar');
        vehicleDto.vehiculeModel = element.attr('data-model');
        vehicleDto.vehicleVersion = element.attr('data-libgro');
        vehicleDto.vehicleDateMec = element.attr('data-date-mec');
        vehicleDto.vehicleNumord = element.attr('data-numord');
        vehicleDto.vehicleMid = element.attr('data-mid');
        vehicleDto.vehicleKtype = element.attr('data-ktypnr');
        vehicleDto.vehicleVin = element.attr('data-vin');
        vehicleDto.vehicleCodmar = element.attr('data-codmar');
        vehicleDto.vehicleCodemodel = element.attr('data-codemodel');
        vehicleDto.vehicleYear = (_a = VehicleFactory.getYearFromDateMec(element.attr('data-date-mec'))) !== null && _a !== void 0 ? _a : '';
        return vehicleDto;
    }
    static createFromDataModel(element) {
        var _a;
        const vehicle = JSON.parse(element.attr('data-vehicle'));
        const vehicleDto = new Vehicle();
        vehicleDto.vehiculeImmat = element.attr('data-immat');
        vehicleDto.vehiculeBrand = vehicle.LIBMAR;
        vehicleDto.vehiculeModel = vehicle.MODELE;
        vehicleDto.vehicleDateMec = element.attr('data-dmec');
        vehicleDto.vehicleNumord = vehicle.NUMORD;
        vehicleDto.vehicleKtype = vehicle.KTYPNR;
        vehicleDto.vehicleVin = element.attr('data-vin');
        vehicleDto.vehicleCodmar = vehicle.CODMAR;
        vehicleDto.vehicleCodemodel = vehicle.CODGRO;
        vehicleDto.vehicleVersion = '';
        vehicleDto.vehicleMid = '';
        vehicleDto.vehicleYear = (_a = VehicleFactory.getYearFromDateMec(element.attr('data-dmec'))) !== null && _a !== void 0 ? _a : '';
        return vehicleDto;
    }
    static getYearFromDateMec(dateMec) {
        try {
            const date = new Date(dateMec);
            if (isNaN(date.getTime())) {
                return null;
            }
            return date.getFullYear();
        }
        catch (error) {
            return null;
        }
    }
}
