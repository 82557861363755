import { GA_CLASS } from "../../constants";
import RemoveFromCartEvent from "./RemoveFromCartEvent";
import { ItemFactory } from "../dto/item/ItemFactory";
import { getModePrice } from '../../utilities';
export default class RemoveFromCartNormalizer {
    normalize(elements, origin) {
        const removeFromCartEvent = new RemoveFromCartEvent();
        $(elements).each(function (index, element) {
            const parentDiv = $(element).closest(':has(.ga)');
            if (0 === parentDiv.length) {
                return;
            }
            const gaDiv = $(parentDiv).find(GA_CLASS);
            if (0 === gaDiv.length) {
                return;
            }
            const item = ItemFactory.createFromDataAttribute($(gaDiv));
            item.index = index + 1;
            item.itemPricing = getModePrice();
            removeFromCartEvent.itemCollection.addItem(item);
            // ItemAttributes.updateLayerAvailabilityForAmplitude(parentDiv, gaDiv).done(() => {
            //     const item = ItemFactory.createFromDataAttribute($(gaDiv));
            //     removeFromCartEvent.itemCollection.addItem(item);
            // });
        });
        return removeFromCartEvent;
    }
    support(element, origin) {
        return true;
    }
}
