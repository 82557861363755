import SwitchPricingEvent from "./SwitchPricingEvent";
export default class SwitchPricingNormalizer {
    normalize(element, catalog) {
        const switchPricingEvent = new SwitchPricingEvent();
        switchPricingEvent.pricing = element;
        return switchPricingEvent;
    }
    support(element, catalog) {
        return true;
    }
}
