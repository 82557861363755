import ClickSearchCategoryEvent from "./ClickSearchCategoryEvent";
import { getCategory } from "./../../../manage-ga";
export default class ClickSearchCategoryNormalizer {
    normalize(element, catalog) {
        const clickSearchCategoryEvent = new ClickSearchCategoryEvent();
        const pathNav = $(element).find('a').attr('href');
        clickSearchCategoryEvent.category = getCategory(pathNav);
        return clickSearchCategoryEvent;
    }
    support(element, catalog) {
        return true;
    }
}
