import FilterEvent from "./FilterEvent";
export default class FilterNormalizer {
    normalize(element, catalog) {
        const filterEvent = new FilterEvent();
        filterEvent.filterType = element;
        return filterEvent;
    }
    support(element, catalog) {
        return true;
    }
}
