import AbstractDataBuilder from "../../core/AbstractDataBuilder";
import AddToWishlistNormalizersResolver from "./AddToWishlistNormalizersResolver";
export default class AddToWishlistDataBuilder extends AbstractDataBuilder {
    static create() {
        if (undefined === this.builderInstance) {
            this.builderInstance = new this(new AddToWishlistNormalizersResolver());
        }
        return this.builderInstance;
    }
}
